$brand-mtiba-primary-100: #f1f7ed;
$brand-mtiba-primary-200: #bbd6a3;
$brand-mtiba-primary-400: #77ad47;
$brand-mtiba-primary-600: #528029;
$brand-mtiba-primary-800: #152507;

body.ui-branding--mtiba {
  --ui-color-primary-100: #{$brand-mtiba-primary-100};
  --ui-color-primary-200: #{$brand-mtiba-primary-200};
  --ui-color-primary-400: #{$brand-mtiba-primary-400};
  --ui-color-primary-600: #{$brand-mtiba-primary-600};
  --ui-color-primary-800: #{$brand-mtiba-primary-800};
}
