// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import 'node_modules/bootstrap/scss/_functions'; /* stylelint-disable-line scss/at-import-no-partial-leading-underscore */

// 2. Include any default variable overrides here: None

// 3. Include remainder of required Bootstrap stylesheets
@import 'node_modules/bootstrap/scss/variables';

// Add custom spacer '45' for 2rem or 32px
$custom-spacers: (
  45: (
    $spacer * 2,
  ),
);
$spacers: map-merge($spacers, $custom-spacers); // stylelint-disable scss/no-global-function-names

// // 4. Include any optional Bootstrap components as you like
@import 'node_modules/bootstrap/scss/bootstrap-grid';
@import 'node_modules/bootstrap/scss/bootstrap-utilities';
