@use '../color/color.scss';
@use '../typography/typography.scss';

.form-field {
  &-header {
    display: flex;
    justify-content: space-between;

    &__label {
      margin: 0 0 0.5rem 0.25rem;
    }

    &__action button[ui-inline-button] {
      @include typography.body-3;
    }
  }

  &-input {
    position: relative;

    &__prefix {
      position: absolute;
      left: 0.25rem;
      top: 0;
      height: 100%;
      width: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &--hidden {
        display: none;
      }
    }

    &__suffix {
      position: absolute;
      right: 0.25rem;
      top: 0;
      height: 100%;
      width: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &--hidden {
        display: none;
      }

      &--no-events {
        pointer-events: none;
      }
    }

    &__input {
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }

      input[type='number'] {
        appearance: textfield;
      }

      input,
      select,
      textarea {
        @include typography.body-1;

        display: flex;
        width: 100%;
        border: 1px solid color.$pebble-400;
        padding: 0.75rem;
        background: color.$pebble-100;
        border-radius: 4px;
        height: 3rem;
        outline: none;
        color: color.$pebble-800;
        box-shadow: inset 0 0 5px color.$input-box-shadow-inset;

        &:focus {
          border-color: color.$primary-400;
          box-shadow:
            0 0 4px color.$primary-400,
            inset 0 0 5px color.$input-box-shadow-inset;
        }

        &:disabled {
          border-color: color.$pebble-200;
          background: color.$pebble-200;
          box-shadow: none;
          color: color.$pebble-600;
          cursor: not-allowed;
        }

        &::placeholder,
        &.ng-untouched.ng-pristine.ng-invalid {
          color: color.$pebble-500;
        }

        &.ng-touched.ng-invalid,
        &.error {
          border-color: color.$blush-600;
          box-shadow:
            0 0 4px color.$blush-600,
            inset 0 0 5px color.$input-box-shadow-inset;
        }
      }

      select {
        appearance: none;
        text-overflow: ellipsis;
        padding-right: 1.5rem;
      }

      &.form-field-input--has-prefix input {
        padding-left: 2.5rem;
      }

      &.form-field-input--has-suffix input {
        padding-right: 2.5rem;
      }
    }

    // stylelint-disable-next-line no-descending-specificity
    textarea {
      margin-bottom: 0;
      min-height: 12.5rem;
      resize: none;
      @include typography.body-2;
    }
  }
}
