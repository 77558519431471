$brand-abnic-primary-100: #ebf2fc;
$brand-abnic-primary-200: #aac7f1;
$brand-abnic-primary-400: #0b5ed7;
$brand-abnic-primary-600: #084296;
$brand-abnic-primary-800: #042452;

body.ui-branding--abnic {
  --ui-color-primary-100: #{$brand-abnic-primary-100};
  --ui-color-primary-200: #{$brand-abnic-primary-200};
  --ui-color-primary-400: #{$brand-abnic-primary-400};
  --ui-color-primary-600: #{$brand-abnic-primary-600};
  --ui-color-primary-800: #{$brand-abnic-primary-800};
}
