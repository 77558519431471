@use '../color/color.scss';

.mat-bottom-sheet-container {
  padding: 0.5rem 0 calc(0.5rem + var(--safe-area-inset-bottom)) 0 !important; /* stylelint-disable-line declaration-no-important */
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.cdk-overlay-dark-backdrop {
  background-color: color.$pebble-1000 !important; /* stylelint-disable-line declaration-no-important */
  opacity: 0.75 !important; /* stylelint-disable-line declaration-no-important */
}
