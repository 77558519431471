@use '../color/color.scss';
@use '../typography/typography.scss';

:root {
  @include typography.body-1;

  color: color.$pebble-1000;

  h1 {
    @include typography.title-1;

    color: color.$pebble-1000;
    margin: 0 0 1rem;
    padding: 0;
  }

  h2 {
    @include typography.title-2;

    color: color.$pebble-1000;
    margin: 0 0 0.75rem;
    padding: 0;
  }

  h3 {
    @include typography.title-3;

    color: color.$pebble-1000;
    margin: 0 0 0.5rem;
    padding: 0;
  }

  h4 {
    @include typography.subtitle-1;

    color: color.$pebble-1000;
    margin: 0 0 1rem;
    padding: 0;
  }

  p {
    @include typography.body-1;

    color: color.$pebble-800;
    margin: 0 0 0.5rem;
    padding: 0;
  }

  label {
    @include typography.label;

    color: color.$pebble-1000;
  }

  a {
    color: color.$primary-400;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:active {
      color: color.$primary-600;
    }
  }

  code {
    @include typography.code;
  }
}
