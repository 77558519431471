$brand-aspire-primary-100: #eeeff7;
$brand-aspire-primary-200: #cbcfe7;
$brand-aspire-primary-400: #525eb7;
$brand-aspire-primary-600: #282d4f;
$brand-aspire-primary-800: #1c203a;

body.ui-branding--aspire,
body.ui-branding--default {
  --ui-color-primary-100: #{$brand-aspire-primary-100};
  --ui-color-primary-200: #{$brand-aspire-primary-200};
  --ui-color-primary-400: #{$brand-aspire-primary-400};
  --ui-color-primary-600: #{$brand-aspire-primary-600};
  --ui-color-primary-800: #{$brand-aspire-primary-800};
}
