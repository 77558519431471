/* stylelint-disable property-disallowed-list */

@use '../color/color.scss';
@use './typeface/typeface.scss';

// Matches Figma: https://www.figma.com/file/cxBQNZt9MbUPP5EDkb8xWD/Design-Library?node-id=11469%3A14827

// Content
@mixin title-1 {
  font-family: typeface.$primary;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0;
}

@mixin title-2 {
  font-family: typeface.$primary;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0;
}

@mixin title-3 {
  font-family: typeface.$primary;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0;
}

@mixin subtitle-1 {
  font-family: typeface.$secondary;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}

@mixin subtitle-2 {
  font-family: typeface.$secondary;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
}
@mixin body-1 {
  font-family: typeface.$secondary;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}

@mixin body-2 {
  font-family: typeface.$secondary;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
}

@mixin body-3 {
  font-family: typeface.$secondary;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0;
}

@mixin body-active-3 {
  font-family: typeface.$secondary;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0;
}

@mixin label {
  font-family: typeface.$secondary;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  letter-spacing: 0;
}

@mixin code {
  font-family: typeface.$code;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}

// Component styles
@mixin button-text {
  font-family: typeface.$secondary;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}

@mixin status-label-text {
  font-family: typeface.$secondary;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
}

@mixin badge-text {
  font-family: typeface.$secondary;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0;
}
