@use '../color/color.scss';
@use '../typography/typography.scss';

.mat-mdc-menu-item {
  .mat-mdc-menu-item-text {
    display: inline-flex;
    mat-icon {
      color: color.$pebble-800;
    }

    span {
      color: color.$pebble-800;
      @include typography.body-2;
    }
  }
}
