@use '../color/color.scss';

.mat-mdc-dialog-surface {
  border-radius: 0.5rem !important; /* stylelint-disable-line declaration-no-important */
  box-shadow: none !important; /* stylelint-disable-line declaration-no-important */
}

.cdk-overlay-dark-backdrop {
  background-color: color.$pebble-1000 !important; /* stylelint-disable-line declaration-no-important */
  opacity: 0.75 !important; /* stylelint-disable-line declaration-no-important */
}
