// Source: https://www.figma.com/file/cxBQNZt9MbUPP5EDkb8xWD/Design-Library?node-id=11469%3A13536

// Primary colors, branding colors are found in libs/feature/branding/src/lib/brands.scss
$primary-100: var(--ui-color-primary-100);
$primary-200: var(--ui-color-primary-200);
$primary-400: var(--ui-color-primary-400);
$primary-600: var(--ui-color-primary-600);
$primary-800: var(--ui-color-primary-800);

// Neutrals
$pebble-0: #ffffff;
$pebble-100: #f8f9fa;
$pebble-200: #f1f3f4;
$pebble-400: #ccd1d3;
$pebble-500: #979fa4;
$pebble-600: #626d74;
$pebble-800: #313940;
$pebble-1000: #13191f;

// Highlights
$blush-100: #ffe8e3;
$blush-200: #ebb8bb;
$blush-400: #fe5f5f;
$blush-600: #c00101;
$blush-800: #731014;
$moss-100: #e8f6e7;
$moss-200: #c5dfc3;
$moss-400: #4dc776;
$moss-600: #026b26;
$moss-800: #1b382a;
$grape-100: #f7edf6;
$grape-200: #cfaed7;
$grape-400: #d970cc;
$grape-600: #88359b;
$grape-800: #521161;
$sunset-100: #fff2d0;
$sunset-200: #f8d7ae;
$sunset-400: #ffae34;
$sunset-600: #a83b01;
$sunset-800: #5c2a05;
$cobalt-100: #e5f1ff;
$cobalt-200: #aed0f8;
$cobalt-400: #65a2ff;
$cobalt-600: #2f3aa7;
$cobalt-800: #151c66;
$frost-100: #edf8fa;
$frost-200: #93cbcf;
$frost-400: #0daabf;
$frost-600: #006573;
$frost-800: #003840;

// Additional
$bottom-navigation-box-shadow: rgb(0 0 0 / 10%);
$input-box-shadow-inset: rgb(0 0 0 / 20%);
$button-shadow: rgb(0 0 0 / 25%);
$transparent: transparent;

$colors: (
  'primary-100': $primary-100,
  'primary-200': $primary-200,
  'primary-400': $primary-400,
  'primary-600': $primary-600,
  'primary-800': $primary-800,
  'pebble-0': $pebble-0,
  'pebble-100': $pebble-100,
  'pebble-200': $pebble-200,
  'pebble-400': $pebble-400,
  'pebble-500': $pebble-500,
  'pebble-600': $pebble-600,
  'pebble-800': $pebble-800,
  'pebble-1000': $pebble-1000,
  'blush-100': $blush-100,
  'blush-200': $blush-200,
  'blush-400': $blush-400,
  'blush-600': $blush-600,
  'blush-800': $blush-800,
  'moss-100': $moss-100,
  'moss-200': $moss-200,
  'moss-400': $moss-400,
  'moss-600': $moss-600,
  'moss-800': $moss-800,
  'grape-100': $grape-100,
  'grape-200': $grape-200,
  'grape-400': $grape-400,
  'grape-600': $grape-600,
  'grape-800': $grape-800,
  'sunset-100': $sunset-100,
  'sunset-200': $sunset-200,
  'sunset-400': $sunset-400,
  'sunset-600': $sunset-600,
  'sunset-800': $sunset-800,
  'cobalt-100': $cobalt-100,
  'cobalt-200': $cobalt-200,
  'cobalt-400': $cobalt-400,
  'cobalt-600': $cobalt-600,
  'cobalt-800': $cobalt-800,
  'frost-100': $frost-100,
  'frost-200': $frost-200,
  'frost-400': $frost-400,
  'frost-600': $frost-600,
  'frost-800': $frost-800,
);

.ui-color {
  @each $color, $value in $colors {
    &--#{$color} {
      color: $value;
    }
  }
}
