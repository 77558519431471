@use '../color/color.scss';

.mdc-radio {
  .mdc-radio__outer-circle {
    border-color: color.$pebble-600 !important; /* stylelint-disable-line declaration-no-important */
    border-width: 1px;
    box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
  }

  .mdc-radio__inner-circle {
    border-color: color.$primary-400 !important; /* stylelint-disable-line declaration-no-important */
  }

  .mat-mdc-radio-persistent-ripple {
    background-color: color.$primary-400 !important; /* stylelint-disable-line declaration-no-important */
  }

  .mat-ripple-element {
    background-color: color.$primary-400 !important; /* stylelint-disable-line declaration-no-important */
    border-color: color.$primary-400 !important; /* stylelint-disable-line declaration-no-important */
  }

  .mdc-radio__background::before {
    background-color: color.$primary-400 !important; /* stylelint-disable-line declaration-no-important */
  }
}

.mat-mdc-radio-checked .mdc-radio .mdc-radio__outer-circle {
  border-color: color.$primary-400 !important; /* stylelint-disable-line declaration-no-important */
  border-width: 2px;
  box-shadow: none;
}
